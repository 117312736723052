import React from "react";
import './Chordgen.css';
import Button from 'react-bootstrap/Button';

const chords = ["I", "ii", "iii", "IV", "V", "vi", "vii°"]

export default class ChordGen extends React.Component{
    setChords(){
        var someChords = [];
        someChords.push(chords[Math.floor(Math.random()*7)]);
        someChords.push(chords[Math.floor(Math.random()*7)]);
        someChords.push(chords[Math.floor(Math.random()*7)]);
        someChords.push(chords[Math.floor(Math.random()*7)]);
        console.log("hello" + someChords);
        this.setState({
            currentChords: someChords
        });
    }

    constructor() {
        super();
            this.state = {
          currentChords: ["I", "IV", "vi", "V"]
        };
      }

    render(){    
        return(
            <div className="box">           
                <h4>Diatonic Chord Progression Generator</h4>
                <h3>{this.state.currentChords[0]} {this.state.currentChords[1]} {this.state.currentChords[2]} {this.state.currentChords[3]}</h3>
                <Button onClick={this.setChords.bind(this)}>Generate!</Button>
            </div>
        );
    }
}