import React from "react"
import './Navbar.css'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'

export default function NavBar(){
    return(
        <div className="navbar">
            <Navbar bg="dark" variant="dark">
                <Container>
                <Navbar.Brand href="#wow">MathematicPony</Navbar.Brand>
                <Nav className="me-auto">
                <Nav.Link href="#you">is</Nav.Link>
                <Nav.Link href="#read">under</Nav.Link>
                <Nav.Link href="#things">construction</Nav.Link>
                </Nav>
                </Container>
            </Navbar>
        </div>
    );
}