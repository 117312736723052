import logo from './logo.svg';
import './App.css';
import NavBar from './components/Navbar';
import Main from './components/Main';
import ChordGen from './components/Chordgen';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="App-header">
        <h1>Coming soon :-) π </h1>
        <div>
        <h2>For now, amuse yourself with this:</h2>
        <ChordGen/>
        </div>
      </div>
    </div>
  );
}

export default App;
